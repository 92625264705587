import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import ImagesWithText from "components/shared/ImagesWithText"
import SEO from "utils/Seo"

import image1 from "images/home/2.jpg"
import image2 from "images/home/4.jpg"

import image3 from "images/articles/garden-pond.webp"


const ArticlesPage = () => (
    <Layout>
        <SEO title="Landscape Gardener Plymouth" description="A beautiful landscape garden can bring new life to any house, transforming it into a peaceful refuge and an extension of your living area." />
        <MaxContainer>
            <ImagesWithText
                image1={{ src: image1, alt: "landscape garden path" }}
                image2={{ src: image2, alt: "landscape gardening" }}
            >
                <h1>Landscape Gardener Plymouth</h1>
                <Row>
                    <Col xs={12} lg={12}>
                        <p>A beautiful landscape garden can bring new life to any house, transforming it into a peaceful refuge and an extension of your living area. Plantlife Landscapes recognises the importance of a well-designed and precisely created garden design. With years of industry experience, we have mastered the art of creating gorgeous landscapes that not only enhance the appearance of your house but also your general well-being. In this blog, we'll go over the numerous reasons why hiring us is the best option for transforming your outdoor space into a haven of beauty and tranquilly.</p>
                        <p><b>Expertise</b></p>
                        <p>Choosing a landscape gardener with extensive knowledge and experience is critical to the success of your outdoor area renovation. Plantlife Landscapes is proud to have a staff of highly competent and informed professionals who are enthusiastic about producing visually appealing landscapes. With years of expertise in the sector, our specialists have mastered the art of integrating hardscapes, softscapes, and other features to create a coherent and visually appealing garden that matches the design of your property and fulfils your specific preferences.</p>
                        <p><b>Tailored Designs for Every Client</b></p>
                        <p>There are no two gardens similar, and we think that your landscape should be a true representation of your personality and lifestyle. We create custom designs for each client, guaranteeing that your outside space is tailored to your preferences and needs. Our designers collaborate with you carefully, taking into account your vision, budget, and any other requirements you may have. We can create a tranquil Zen garden, a vivid flowery paradise, or a modern minimalist landscape for you.</p>
                        <p><b>Sustainable Landscaping Practices</b></p>
                        <p>We prioritise sustainable and eco-friendly practises as a responsible landscape garden company. Every part of our landscaping projects is guided by environmental conservation principles. We ensure that your garden not only looks lovely but also contributes positively to the environment by utilising drought-tolerant plants and optimising water usage using sophisticated irrigation systems. Our dedication to sustainability extends to the use of locally produced products and the reduction of waste during the construction process.</p>
                        <p><b>Quality Craftsmanship</b></p>
                        <p>A magnificent setting necessitates both ingenuity and superb craftsmanship. We take great satisfaction in the attention to detail and craftsmanship that goes into each project that we work on. Our team's dedication to perfection ensures that your landscape will survive the test of time, whether it's building robust retaining walls, crafting exquisite stone walkways, or meticulously planting and caring for the vegetation.</p>
                        <p><b>Comprehensive Services</b></p>
                        <p>As a landscape garden company, we offer a comprehensive selection of landscaping services to fulfil all of your landscaping requirements. We handle every area of the project with the utmost expertise, from the initial design concept through the final installation and continuous maintenance. Landscape design, hardscaping, softscaping, irrigation systems, outdoor lighting, and continuing garden upkeep are all part of our offerings. You may relax and watch your dream garden come to life with us as your trusted partner.</p>
                        <p><b>Enhancing Property Value</b></p>
                        <p>Investing in a professionally planned landscape not only improves the looks of your property but also increases its worth significantly. A well-maintained and creatively designed garden can increase the curb appeal of your property, making it more appealing to potential buyers. Whether you intend to stay in your home for many years or have selling plans, our landscaping solutions are a prudent investment that provides both immediate and long-term benefits.</p>
                        <p><b>Stress-Free Project Management</b></p>
                        <p>Without the proper guidance and management, embarking on a landscaping project can be intimidating. Plantlife Landscapes alleviates tension by providing effective project management. We handle all areas of the project scheduling, funding, and coordination, ensuring that everything runs smoothly from beginning to end.</p>
                        <img src="/images/articles/garden-pond.webp" width="50%" alt="garden pond" />
                        <p>Finally, a stunning landscape garden can transform your backyard into a calm paradise that will bring you joy and peace. We take great satisfaction in providing carefully built and personalised landscapes that enhance the attractiveness of your property while using sustainable practises. We are the ideal choice for changing your outdoor space into a magnificent refuge because of our experience, commitment to quality, and broad range of services. Trust us to design the landscape of your dreams, and together we'll transform your outdoor space into a place of eternal beauty and happiness.</p>
                        <p><b><i>Look no further for the leading <a href="https://www.plantlifelandscapes.co.uk/">landscape gardener in Plymouth</a>!</i></b></p>
                        <p>Check out our previous article <a href="https://www.plantlifelandscapes.co.uk/garden-design-in-tavistock/">here</a>.</p>
                    </Col>
                </Row>
            </ImagesWithText>
        </MaxContainer>
    </Layout>
)

export default ArticlesPage
